import { FETCH_DEVICES, EDIT_DEVICES, GET_BATTERY, GET_LOCATION, DELETE_DEVICE, GRAB_EDIT } from '../actions/types';

const initialState = {
  devices: [],
  device: {},
  battery: '',
  location: {},
  editID: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DEVICES:
      console.log('Fetch devices reducer');
      return {
        ...state,
        devices: action.payload
      };
    case GET_BATTERY:
      return {
        ...state,
        battery: action.payload
      };
    case GET_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case DELETE_DEVICE:
      console.log('placeholder');
      break;
    case GRAB_EDIT:
      console.log('Edit devices' + action.payload);
      return {
        ...state,
        editID: action.payload
      };

    case EDIT_DEVICES:
      console.log('placeholder');
      break;
    default:
      return state;
  }
}
