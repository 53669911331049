import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { fetchUsers, grabUser, editUsers } from '../redux/actions/userActions';
import { TablePagination } from '@material-ui/core';

class Users extends Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  render() {
    const users = this.props.users.map(user => ({
      ...user,
      street: user.address?.street || '',
      city: user.address?.city || '',
      state: user.address?.state || '',
      zipCode: user.address?.zipCode || '',
      name: `${user.firstName} ${user.lastName}`,
      newContacts: user.contacts ? user.contacts.map(contact => `Name: ${contact.name} Phone number: ${contact.phoneNumber} `) : [],
    }));

    const styles = { backgroundColor: 'white' };

    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'Name', field: 'name', editable: 'onUpdate' },
            { title: 'Phone Number', field: 'phoneNumber', editable: 'onUpdate' },
            { title: 'Email', field: 'email', editable: 'onUpdate' },
            { title: 'Street', field: 'street', editable: 'onUpdate' },
            { title: 'City', field: 'city', editable: 'onUpdate' },
            { title: 'State', field: 'state', editable: 'onUpdate' },
            { title: 'Zip Code', field: 'zipCode', editable: 'onUpdate' },
            { title: 'ID', field: '_id', editable: 'never' },
            { title: 'Date Joined', field: 'dateJoined', editable: 'never' }
          ]}
          data={users}
          title="Users"
          detailPanel={rowData => (
            <ul style={styles}>
              {rowData.newContacts.map((contact, index) => (
                <li key={index}>{contact}</li>
              ))}
            </ul>
          )}
          actions={[
            {
              icon: 'battery_charging_full',
              tooltip: 'Battery',
              onClick: (event, rowData) => {
                this.props.grabUser(rowData._id);
              }
            }
          ]}
          options={{
            exportButton: true
          }}
          components={{
            Pagination: props => (
              <TablePagination
                {...props}
                component="div"
                count={users.length}
                rowsPerPageOptions={[5, 10, 20, { label: 'All', value: users.length }]}
                labelRowsPerPage={<div style={{ color: 'white' }}>{'Rows per page:'}</div>}
              />
            )
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const nameArray = newData.name.split(' ');
                const firstName = nameArray[0];
                const lastName = nameArray[nameArray.length - 1];
                this.props.editUsers(
                  newData._id,
                  firstName,
                  lastName,
                  newData.email,
                  newData.phoneNumber,
                  newData.zipCode,
                  newData.state,
                  newData.city,
                  newData.street
                ).then(() => {
                  resolve();
                }).catch(error => {
                  console.error("Error updating user: ", error);
                  reject();
                });
              }),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users.users
});

export default connect(mapStateToProps, { editUsers, fetchUsers, grabUser })(Users);
