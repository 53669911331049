import { FETCH_USERS, EDIT_USERS, GRAB_USER } from '../actions/types';

const initialState = {
  users: [],
  contacts: [],
  user: {
    firstName: '',
    lastName: '',
    type: '',
    email: '',
    phoneNumber: '',
    address: {
      zipCode: '',
      state: '',
      city: '',
      street: ''
    }
  },
  userID: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS:
      console.log('Reduce');
      return {
        ...state,
        users: action.payload
      };
    case EDIT_USERS:
      console.log('Edit reduce');
      return {
        ...state,
        user: action.payload
      };
    case GRAB_USER:
      console.log('GRAB');
      return {
        ...state,
        userID: action.payload
      };
    default:
      return state;
  }
}
