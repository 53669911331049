import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings, updateSettings } from '../redux/actions/settingsActions';
import Loading from './Loading';

function Settings() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const [state, setState] = React.useState(settings);

  React.useEffect(() => {
    dispatch(fetchSettings());
    setState({ ...settings });
  }, []);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      // eslint-disable-next-line radix
      [name]: value
    });
  };

  const submit = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure?')) {
      dispatch(updateSettings(state));
    }
  };

  if (!state) {
    return <Loading />;
  }
  return (
    <div className="settings">
      <form action="">
        <fieldset>
          <label htmlFor="cloudinary_name">Cloudinary Name</label>
          <input
            onChange={onInputChange}
            value={state.cloudinary_name}
            type="text"
            name="cloudinary_name"
            placeholder="Cloudinary Name"
            id="cloudinary_name"
          />

          <label htmlFor="cloudinary_api_key">Cloudinary Api Key</label>
          <input
            name="cloudinary_api_key"
            onChange={onInputChange}
            value={state.cloudinary_api_key}
            type="text"
            placeholder="Cloudinary Api Key"
            id="cloudinary_api_key"
          />

          <label htmlFor="cloudinary_api_secret">Cloudinary Api Secret</label>
          <input
            name="cloudinary_api_secret"
            onChange={onInputChange}
            value={state.cloudinary_api_secret}
            type="text"
            placeholder="Cloudinary Api Secret"
            id="cloudinary_api_secret"
          />

          <label htmlFor="gpsWoxUrl">Gps Wox Url</label>
          <input
            name="gpsWoxUrl"
            onChange={onInputChange}
            value={state.gpsWoxUrl}
            type="text"
            placeholder="Gps Wox Url"
            id="gpsWoxUrl"
          />

          <label htmlFor="gpsWoxEmail">Gps Wox Email</label>
          <input
            name="gpsWoxEmail"
            onChange={onInputChange}
            value={state.gpsWoxEmail}
            type="text"
            placeholder="Gps Wox Email"
            id="gpsWoxEmail"
          />

          <label htmlFor="gpsWoxPassword">Gps Wox Password</label>
          <input
            name="gpsWoxPassword"
            onChange={onInputChange}
            value={state.gpsWoxPassword}
            type="text"
            placeholder="Gps Wox Password"
            id="gpsWoxPassword"
          />

          <button className="button-primary" onClick={submit}>
            Update Settings
          </button>
        </fieldset>
      </form>
    </div>
  );
}

export default Settings;
