import axios from 'axios';
import { API_URL } from '../../config';
import { SAVE_SETTING } from './types';

//Grabs all users from the database
export const fetchSettings = () => (dispatch, getState) => {
  const token = getState().login.token;
  console.log('Fetch settings');
  axios
    .get(`${API_URL}/settings`, {
      headers: { 'x-access-token': token }
    })
    .then((response) => {
      dispatch({
        type: SAVE_SETTING,
        payload: response.data
      });
    })
    .catch((error) => {
      console.log('An error happened: ' + error);
    });
};

export const updateSettings = (payload) => (dispatch, getState) => {
  const token = getState().login.token;
  delete payload._id;
  console.log('Pa', payload);
  axios
    .put(`${API_URL}/settings`, payload, {
      headers: {
        'x-access-token': token
      }
    })
    .then((response) => {
      dispatch({
        type: SAVE_SETTING,
        payload: response.data
      });
    })
    .catch((error) => {
      console.log('An error happened: ' + error);
    });
};
