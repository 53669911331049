import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchSettings } from '../redux/actions/settingsActions';

function Home() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  return <div>Welcome!</div>;
}

export default Home;
