import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { fetchUsers } from '../redux/actions/userActions';
import { fetchDevices } from '../redux/actions/deviceActions';
import { getLocation } from '../redux/actions/deviceActions';
import { getBattery } from '../redux/actions/deviceActions';
import { deleteDevice } from '../redux/actions/deviceActions';
import { grabDevice } from '../redux/actions/deviceActions';
import { formSubmit } from '../redux/actions/deviceActions';
import { TablePagination } from '@material-ui/core';

class Trackers extends Component {
  componentWillMount() {
    this.props.fetchDevices();
    this.props.fetchUsers();
  }
  render() {
    const devicesTest = this.props.devices;
    for (let i = 0; i < devicesTest.length; i++) {
      for (let j = 0; j < this.props.users.length; j++) {
        if (devicesTest[i].userId === this.props.users[j]._id) {
          devicesTest[i].name = this.props.users[j].firstName + ' ' + this.props.users[j].lastName;
        }
      }
    }
    const arrLength = devicesTest.length;
    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'Pet Name', field: 'petName', editable: 'onUpdate' },
            { title: 'SIM', field: 'sim', editable: 'onUpdate' },
            { title: 'Phone Number', field: 'phoneNumber', editable: 'onUpdate' },
            { title: 'Device ID', field: '_id', editable: 'never' },
            { title: 'User ID', field: 'userId', editable: 'never' },
            { title: 'Customer Name', field: 'name', editable: 'never' },
            { title: 'IMEI', field: 'imei', editable: 'never' }
          ]}
          data={devicesTest}
          title="Trackers"
          actions={[
            {
              icon: 'battery_charging_full',
              tooltip: 'Battery',
              onClick: (event, rowData) => {
                this.props.getBattery(rowData._id);
              }
            },
            {
              icon: 'location_on',
              tooltip: 'Locate',
              onClick: (event, rowData) => {
                this.props.getLocation(rowData._id);
              }
            },
            {
              icon: 'delete',
              tooltip: 'Delete',
              onClick: (event, rowData) => {
                this.props.deleteDevice(rowData._id, rowData.petName);
              }
            }
          ]}
          options={{
            exportButton: true
          }}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                labelRowsPerPage={<div style={{ color: 'white' }}>{props.labelRowsPerPage}</div>}
                labelDisplayedRows={(row) => (
                  <div style={{ backgroundColor: 'white' }}>{props.labelDisplayedRows(row)}</div>
                )}
                rowsPerPageOptions={[5, 10, 20, arrLength]}
                MenuProps={{ color: 'white' }}
              />
            )
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                this.props.formSubmit(oldData._id, newData.petName, newData.phoneNumber, newData.sim);
                setTimeout(() => {
                  resolve();
                  //window.location.reload();
                }, 1000);
              })
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: state.devices.devices,
  users: state.users.users
});

export default connect(mapStateToProps, {
  formSubmit,
  fetchUsers,
  fetchDevices,
  getBattery,
  getLocation,
  deleteDevice,
  grabDevice
})(Trackers);
