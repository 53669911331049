import axios from 'axios';
import { LOGIN, LOGOUT, ADMIN, TOKEN } from './types';
import { API_URL } from '../../config';

//Checks to see if the user name and password match with what is in the databse
export const login = (u, p) => (dispatch) => {
  console.log('Login is being tried');
  axios({
    method: 'post',
    url: `${API_URL}/signin`,
    data: {
      email: u,
      password: p
    }
  })
    .then((response) => {
      //If the user that is returned by the server is an admin, then we set isAuth to true, isAdmin to true, and the token to the authToken that is received as well
      if (response.data.user.type.toLowerCase() === 'admin') {
        dispatch({
          type: LOGIN,
          payload: true
        });
        dispatch({
          type: ADMIN,
          payload: true
        });
        dispatch({
          type: TOKEN,
          payload: response.data.authToken
        });
      }
    })
    .catch((error) => {
      console.log('Login failed, error: ' + error);
    });
};
//Logs out
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: false
  });
  dispatch({
    type: TOKEN,
    payload: ''
  });
};
