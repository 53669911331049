import React, { Component } from 'react';
import Home from './components/Home';
import Users from './components/Users';
import Trackers from './components/Trackers';
import { Link, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Logout from './components/Logout';
import Login from './components/Login';
import './App.css';
import { login, logout } from './redux/actions/loginActions';
import EditUsers from './components/EditUsers';
import Edit from './components/Edit';
import FurtrieveLogo from './images/FurtrieveLogo.png';
import Settings from './components/Settings';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false
    };
  }
  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => {
      const isAuth = this.props.isAuth;
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuth ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      );
    };
    const { from } = window.location || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;
    if (redirectToReferrer) return <Redirect to={from} />;
    return (
      <div>
        <div className="logo">
          <img className="logo" alt="logo" src={FurtrieveLogo} />
        </div>
        <br />
        <div>
          <ul className="NavBar">
            <li>
              <Link to={'/'}>Home</Link>
            </li>
            <li>
              <Link to={'/users'}>Users</Link>
            </li>
            <li>
              <Link to={'/trackers'}>Trackers</Link>
            </li>
            {this.props.isAdmin && (
              <li>
                <Link to={'/settings'}>Settings</Link>
              </li>
            )}
          </ul>
          <Route exact path="/" component={Home} />
          <PrivateRoute path="/users" component={Users} />
          <PrivateRoute path="/trackers" component={Trackers} />
          <PrivateRoute path="/settings" component={Settings} />
          <Route path="/login" component={Login} />
          <Route path="/editusers" component={EditUsers} />
          <Route path="/edit" component={Edit} />
          <Logout />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.login.isAuth,
  isAdmin: state.login.isAdmin
});

export default connect(mapStateToProps, { login, logout })(App);