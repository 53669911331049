import axios from 'axios';
import { FETCH_USERS, GRAB_USER } from './types';
import { API_URL } from '../../config';

//Grabs all users from the database
export const fetchUsers = () => (dispatch, getState) => {
  const token = getState().login.token;
  console.log('Fetch users');
  let userArray = [];
  axios
    .get(`${API_URL}/users`, {
      headers: { 'x-access-token': token }
    })
    .then((response) => {
      userArray = response.data;
      dispatch({
        type: FETCH_USERS,
        payload: userArray
      });
      /*dispatch({
                    type: CONTACTS,
                    payload: response.data.contacts
                }); */
      console.log('Database retrieved');
    })
    .catch((error) => {
      console.log('An error happened: ' + error);
    });
};
//Use for the edit page, grabs the id and sets the id in the redux store
export const grabUser = (id) => (dispatch) => {
  console.log(id);
  dispatch({
    type: GRAB_USER,
    payload: id
  });
  window.location.replace(window.location.origin + '/EditUsers');
};
//Submits the devices edited information to the server, id cannot be changed, all information has to be inserted even if you are not editing it
export const editUsers = (id, firstName, lastName, email, phoneNumber, zipCode, state, city, street) => (
  dispatch,
  getState
) => {
  const token = getState().login.token;
  console.log(zipCode, state, city, street);
  axios({
    method: 'put',
    url: `${API_URL}/users/${id}`,
    headers: { 'x-access-token': token },
    data: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      address: {
        street: street,
        city: city,
        state: state,
        zipCode: zipCode
      }
    }
  })
    .then(() => {
      console.log('User edited successfully');
      fetchUsers()(dispatch, getState);
      window.location.replace(window.location.origin + '/users');
    })
    .catch((error) => {
      console.log('An error happened: ' + error);
    });
};
