import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from './components/Loading';
import { Provider } from 'react-redux';
import configureStore from './redux/store.js';

const { persistor, store } = configureStore();
ReactDOM.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <App />
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
