import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Login from './Login';
import { logout } from '../redux/actions/loginActions';
import { withRouter } from 'react-router-dom';

class Logout extends Component {
  render() {
    const AuthButton = withRouter(({ history }) =>
      this.props.isAuth ? (
        <p>
          {this.props.isAuth}
          <Button onClick={this.props.logout}>Sign out</Button>
        </p>
      ) : (
        <div>
          <Login />
          <p>You are not logged in</p>
        </div>
      )
    );
    return (
      <div>
        <AuthButton />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.login.isAuth
});

export default connect(mapStateToProps, { logout })(Logout);
