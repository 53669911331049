import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import {editUsers} from "../redux/actions/userActions";
import {fetchUsers} from "../redux/actions/userActions";

class Edit extends Component{
    constructor(props) {
        super(props);
        this.state = {
            firstNameChange: '',
            lastNameChange: '',
            emailChange: '',
            phoneNumberChange: '',
            addressChange: {
                zipCodeChange: '',
                stateChange: '',
                cityChange: '',
                streetChange: ''
            },
            user: {}
        };
    }
    componentWillMount(){

        fetchUsers();
        console.log(this.props.userID);
        for(let i = 0; i<this.props.users.length; i++){
            if(this.props.users[i]._id === this.props.userID){
                this.setState({
                    user: this.props.users[i],

                    firstNameChange: this.props.users[i].firstName,
                    lastNameChange: this.props.users[i].lastName,
                    emailChange: this.props.users[i].email,
                    phoneNumberChange: this.props.users[i].phoneNumber,
                    addressChange: {
                        zipCodeChange: this.props.users[i].address.zipCode,
                        stateChange: this.props.users[i].address.state,
                        cityChange: this.props.users[i].address.city,
                        streetChange: this.props.users[i].address.street
                    }
                });
                console.log(this.props.users[i]);
                console.log(this.state.user.address);
                console.log(this.state.user);
                alert('stop line 48');
            }
        }
        console.log(this.state.user);
        console.log(this.props.userID);
        console.log(this.props.users);
        alert('stop line 54');
    }
    handleTextChange = key => event => {
        this.setState({[key]: event.target.value})
    };
    render(){
        return(
            <div>
                <form>
                    <h3>Edit form for {this.state.user.firstName} {this.state.user.lastName}</h3>
                    <p>When updating the information for the user, any empty fields in the form will be kept at their previous state</p>
                    <TextField
                        id="firstName"
                        label = "First Name"
                        onChange={this.handleTextChange('firstNameChange')}
                    /><br/>
                    <div>Current First Name: {this.state.user.firstName}</div>
                    <div>Changed First Name: {this.state.firstNameChange}</div>
                    <TextField
                        id="lastName"
                        label = "Last Name"
                        onChange={this.handleTextChange('lastNameChange')}
                    /><br/>
                    <div>Current Last Name: {this.state.user.lastName}</div>
                    <div>Changed Last Name: {this.state.lastNameChange}</div>
                    <p>Format phone number like this: (333) 333-3333</p>
                    <TextField
                        id="phoneNumber"
                        label = "Phone Number"
                        onChange={this.handleTextChange('phoneNumberChange')}
                    /><br/>
                    <div>Current Phone Number: {this.state.user.phoneNumber}</div>
                    <div>Changed Phone Number: {this.state.phoneNumberChange}</div>
                    <TextField
                        id="email"
                        label = "Email"
                        onChange={this.handleTextChange('emailChange')}
                    /><br/>
                    <div>Current email: {this.state.user.email}</div>
                    <div>Changed email: {this.state.emailChange}</div>
                    <TextField
                        id="street"
                        label = "Street Name"
                        onChange={this.handleTextChange('streetChange')}
                    /><br/>
                    <div>Current street name: {this.state.user.address.street}</div>
                    <div>Changed street name: {this.state.addressChange.streetChange}</div>
                    <TextField
                        id="city"
                        label = "City"
                        onChange={this.handleTextChange('cityChange')}
                    /><br/>
                    <div>Current City name: {this.state.user.address.city}</div>
                    <div>Changed City name: {this.state.addressChange.cityChange}</div>
                    <TextField
                        id="state"
                        label = "State"
                        onChange={this.handleTextChange('stateChange')}
                    /><br/>
                    <div>Current State: {this.state.user.address.state}</div>
                    <div>Changed State: {this.state.addressChange.stateChange}</div>
                    <TextField id="zipcode"
                        label = "Zipcode"
                        onChange={this.handleTextChange('zipCodeChange')}
                    /><br/>
                    <div>Current Zip Code: {this.state.user.address.zipCode}</div>
                    <div>Changed Zip Code: {this.state.addressChange.zipCodeChange}</div>
                    <Link to = {'/users'}><Button color = "primary" onClick = {()=> this.props.editUsers(this.state.user._id, this.state.firstNameChange, this.state.lastNameChange, this.state.emailChange, this.state.phoneNumberChange, this.state.zipCodeChange, this.state.stateChange, this.state.cityChange, this.state.streetChange)}>Submit</Button></Link>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    users: state.users.users,
    userID: state.users.userID
});

export default connect(mapStateToProps, {editUsers})(Edit);
