import React, {Component} from 'react'
import TextField from '@material-ui/core/TextField';
import {login, logout} from "../redux/actions/loginActions";
import {connect} from "react-redux";

class Login extends Component {

    constructor(props){
        super(props);
        this.state ={
            redirectToReferrer: false,
            userName: '',
            password: ''
        }
    }

    handleTextChange = key => event => {
        this.setState({[key]: event.target.value})
    };
    render(){
        return(
            <div>
                 <TextField label = 'Username' id = 'userName' onChange = {this.handleTextChange('userName')} /><br/>
                 <TextField type = 'password' label = 'Password' id = 'password' onChange = {this.handleTextChange('password')} /><br/>
                 <button onClick = {() => this.props.login(this.state.userName, this.state.password)} >Submit</button>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    isAuth: state.login.isAuth
});

export default connect(mapStateToProps, {login, logout})(Login);
