import { SAVE_SETTING } from '../actions/types';

const initialState = {
  cloudinary_name: '',
  cloudinary_api_key: '',
  cloudinary_api_secret: '',
  gpsWoxUrl: '',
  gpsWoxEmail: '',
  gpsWoxPassword: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_SETTING:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
