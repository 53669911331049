import { combineReducers } from 'redux';

import userReducer from './userReducer';
import deviceReducer from './deviceReducer';
import loginReducer from './loginReducer';
import settingsReducer from './settingsReducer';

export default combineReducers({
  users: userReducer,
  devices: deviceReducer,
  login: loginReducer,
  settings: settingsReducer
});
