import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {formSubmit} from '../redux/actions/deviceActions.js';
import {deleteDevice} from '../redux/actions/deviceActions'
import {fetchDevices} from "../redux/actions/deviceActions";

class Edit extends Component{
    constructor(props) {
        super(props);
        this.state = {
            device: {},
            petName: '',
            phoneNumber: '',
            sim: '',
            petNameChange: '',
            phoneNumberChange: '',
            simChange: ''
        };
    }
    componentWillMount(){
        fetchDevices();
        console.log(this.props.editID);
        for(let i = 0; i<this.props.devices.length; i++){
            if(this.props.devices[i]._id === this.props.editID){
                console.log("This worked");
                this.setState({
                    device: this.props.devices[i],
                    petNameChange: this.props.devices[i].petName,
                    phoneNumberChange: this.props.devices[i].phoneNumber,
                    simChange: this.props.devices[i].sim
                });
                break;
            } else {
                console.log("This did not work");
            }
        }
    }
    handleTextChange = key => event => {
        this.setState({[key]: event.target.value})
    };
        render(){
        return(
            <div>
                <h1>Hello</h1>
                <form>
                    <h3>Edit form for {this.state.device.petName}</h3>
                    <div>{this.state.device._id}</div>
                    <TextField label = "Pet name"
                    id = "petName"
                    onChange ={this.handleTextChange("petNameChange")}>{this.state.device.petName}</TextField><br/>
                    <div>Current Pet Name: {this.state.device.petName}</div><br/>
                    <div>Changed Pet Name: {this.state.petNameChange}</div>
                    <TextField label = "SIM" id="SIM"
                    onChange ={this.handleTextChange("simChange")}>{this.state.device.sim}</TextField><br/>
                    <div>Current SIM: {this.state.device.sim}</div><br/>
                    <div>Changed SIM: {this.state.simChange}</div>

                    <TextField label = "Phone Number" id = "phoneNumber"
                    onChange = {this.handleTextChange("phoneNumberChange")}>{this.state.device.phoneNumber}</TextField><br/>
                    <div>Current phone number: {this.state.device.phoneNumber}</div><br/>
                    <div>Changed phone number: {this.state.phoneNumberChange}</div><br/>

                    <Link to = {'/trackers'}><Button color = "secondary" onClick = {()=> this.props.deleteDevice(this.state.device._id)}>Delete Device</Button></Link><br/>
                    <Link to = {'/trackers'}><Button color = "primary" onClick = {()=> this.props.formSubmit(this.state.device._id, this.state.petNameChange, this.state.phoneNumberChange, this.state.simChange)}>Submit</Button></Link>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    devices: state.devices.devices,
    editID: state.devices.editID
});

export default connect(mapStateToProps, {formSubmit, deleteDevice})(Edit);
