import { LOGIN, LOGOUT, ADMIN, TOKEN } from '../actions/types';

const initialState = {
  isAuth: false,
  isAdmin: false,
  token: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      console.log('Login reducer');
      return {
        ...state,
        isAuth: action.payload
      };
    case LOGOUT:
      console.log('Logout');
      return {
        ...state,
        isAuth: action.payload
      };
    case ADMIN:
      console.log('Admin');
      return {
        ...state,
        isAdmin: action.payload
      };
    case TOKEN:
      console.log('Token');
      return {
        ...state,
        token: action.payload
      };

    default:
      return state;
  }
}
