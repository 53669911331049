export const FETCH_USERS = 'FETCH_USERS';
export const EDIT_USERS = 'EDIT_USER';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const EDIT_DEVICES = 'EDIT_DEVICES';
export const GET_BATTERY = 'GET_BATTERY';
export const GET_LOCATION = 'GET_LOCATION';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const GRAB_EDIT = 'GRAB_EDIT';
export const GRAB_USER = 'GRAB_USER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ADMIN = 'ADMIN';
export const TOKEN = 'TOKEN';
export const SAVE_SETTING = 'SAVE_SETTING';
