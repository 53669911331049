import axios from 'axios';
import { FETCH_DEVICES, GET_BATTERY, GET_LOCATION, GRAB_EDIT } from './types';
import { API_URL } from '../../config';

const url = process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'api.furtrieve.com';

//Grabs all devices from the database
export const fetchDevices = () => (dispatch, getState) => {
  console.log('fetch devices');
  const token = getState().login.token;
  let deviceArray = [];
  axios
    .get(`${API_URL}/devices`, {
      headers: { 'x-access-token': token }
    })
    .then((response) => {
      deviceArray = response.data;
      dispatch({
        type: FETCH_DEVICES,
        payload: deviceArray
      });
    })
    .catch((error) => {
      console.log('An error happened: ' + error);
    });
  return deviceArray;
};

//Grabs the battery percentage for a specific device
export const getBattery = (id) => (dispatch, getState) => {
  console.log(id);
  console.log('get battery');
  const token = getState().login.token;
  axios
    .get(`${API_URL}/devices/${id}/battery`, {
      headers: { 'x-access-token': token }
    })
    .then((response) => {
      console.log('does it get here');
      console.log(response.data);
      dispatch({
        type: GET_BATTERY,
        payload: response.data
      });
    })
    .catch((error) => {
      console.log('An error happened: ' + error);
    });
};

//Gets the location for a specific device
export const getLocation = (id) => (dispatch, getState) => {
  const token = getState().login.token;
  axios
    .get(`${API_URL}/devices/${id}/position`, {
      headers: { 'x-access-token': token }
    })
    .then((response) => {
      console.log(response.data);
      dispatch({
        type: GET_LOCATION,
        payload: response.data
      });
    })
    .catch((error) => {
      console.log('An error happened: ' + error);
    });
};

//Deletes a device, USE WITH CAUTION
export const deleteDevice = (id, petName) => (dispatch, getState) => {
  console.log(id);
  const token = getState().login.token;
  if (window.confirm('Are you sure you want to delete the device for ' + petName + '?')) {
    axios
      .delete(`${API_URL}/devices/${id}`, {
        headers: { 'x-access-token': token }
      })
      .then((response) => {
        console.log('Device is deleted');
        console.log(response);
        fetchDevices()(dispatch, getState);
      })
      .catch((response) => {
        console.log(response);
      });
  } else {
    console.log('Device is not deleted');
  }
};

//Use for the edit page, grabs the id and sets the id in the redux store
export const grabDevice = (id) => (dispatch) => {
  console.log(id);
  dispatch({
    type: GRAB_EDIT,
    payload: id
  });
  window.location.replace(window.location.href + '/Edit');
};

//Submits the devices edited information to the server, id cannot be changed, all information has to be entered in even if it is not being changed
export const formSubmit = (id, petName, phoneNumber, sim) => (dispatch, getState) => {
  const token = getState().login.token;
  axios({
    method: 'put',
    url: `${API_URL}/devices/${id}`,
    headers: { 'x-access-token': token },
    data: {
      petName: petName,
      phoneNumber: phoneNumber,
      sim: sim
    }
  })
    .then(() => {
      fetchDevices()(dispatch, getState);
    })
    .catch((error) => {
      console.log('An error happened: ' + error);
    });
};
